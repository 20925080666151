@keyframes move {
  0% {
    opacity: 0;
    background-color: #00ebb9;
    transform: translateX(-100%);
  }
  5% {
    background-color: #00ebb9;
    opacity: 1;
    transform: translateX(0%);
  }
  45% {
    background-color: #00ebb9;
    opacity: 1;
    transform: translateX(0%);
  }
  49% {
    background-color: #00ebb9;
    opacity: 0;
    transform: translateX(200%);
  }
  50% {
    background-color: #00ebb9;
    opacity: 0;
    transform: translateX(200%);
  }
  50% {
    opacity: 0;
    background-color: #dcdce1;
    transform: translateX(-100%);
  }
  55% {
    background-color: #dcdce1;
    opacity: 1;
    transform: translateX(0%);
  }
  95% {
    background-color: #dcdce1;
    opacity: 1;
    transform: translateX(0%);
  }
  99% {
    background-color: #dcdce1;
    opacity: 0;
    transform: translateX(200%);
  }
  100% {
    background-color: #00ebb9;
    opacity: 0;
    transform: translateX(200%);
  }
}

.rect {
  background-color: #00ebb9;
  height: 9.7%;
  margin-top: 5%;
}

#rect-0 {
  animation: move ease 4s infinite 0s;
  width: 27%;
  margin-left: 13.5%;
}

#rect-1 {
  animation: move ease 4s infinite 0.5s;
  width: 27%;
  margin-left: 41.5%;
}

#rect-2 {
  animation: move ease 4s infinite 1s;
  width: 55%;
  margin-left: 13.5%;
}

#rect-3 {
  animation: move ease 4s infinite 1.5s;
  width: 27%;
  margin-left: 13.5%;
}

.logo-animation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 125px;
  height: 125px;
  margin: auto;
}

.logo-loader-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #002c46;
}

.logo-loader-center {
  margin: auto
}

.logo-loader-center.full-height {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 120px);
}

.-loading-inner .message {
  margin: 0;
  padding: 0;
}

.-loading-inner.disabled #rect-0,
.-loading-inner.disabled #rect-1,
.-loading-inner.disabled #rect-2,
.-loading-inner.disabled #rect-3 {
  animation: none;
  background-color: #dcdce1;
}
